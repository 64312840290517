import React from 'react'
import Layout from "../components/layout"
import scandal from '../images/hero-or-villain2.png'
import SEO from '../components/seo'

export default function Article() {
    return (
        <Layout>
            <SEO title="Hero or Villain?" image={scandal} />
            <h1>
            Hero or Villain?
            </h1>
            <img src={scandal} />
            <p>* What’s this? Our sweet and innocent Hero isn’t so sweet and innocent after all. Still love at first sight, Claudio? *</p>
        </Layout>
    )
}
